import { Route, Routes } from 'react-router-dom';
import LoginPage from './views/LoginPage/LoginPage';
import RegisterPage from './views/RegisterPage/RegisterPage';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import UnsuccessfullPage from './views/UnsuccessfullPage/UnsuccessfullPage';
import SuccessPage from './views/SuccessPage/SuccessPage';
import HaveAccountPage from './views/HaveAccountPage/HaveAccountPage';
import PlansPage from './views/PlansPage/PlansPage';
import OtpPage from './views/OtpPage/OtpPage';
import usePersonalizedLocations from './hooks/usePersonalizedLocations';

const RouterComponent = () => {
  const { backgroundClass } = usePersonalizedLocations();

  return (
    <>
      <div className={`background ${backgroundClass}`} />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route
          path="/unsuccessfull"
          element={<ProtectedRoute element={<UnsuccessfullPage />} />}
        />
        <Route
          path="/success"
          element={<ProtectedRoute element={<SuccessPage />} />}
        />
        <Route
          path="/AlreadyHaveAccount"
          element={<ProtectedRoute element={<HaveAccountPage />} />}
        />
        <Route
          path="/plans"
          element={<ProtectedRoute element={<PlansPage />} />}
        />
      </Routes>
    </>
  );
};

export default RouterComponent;
