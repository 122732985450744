import { useState } from 'react';
import {
  login,
  logout,
  register,
  resendOtp,
  sendOtp,
  verifyOtp,
} from '../../services/authService/authService';
import useCommonFunctions from '../../hooks/useCommonFunctions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PaymentController from '../PaymentController.js/PaymentController';

const AuthController = () => {
  const { t } = useTranslation();
  const { goTo } = useCommonFunctions();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agree, setAgree] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { userHaveSubscription } = PaymentController();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await login(email, password).then((response) => {
        return response;
      });
      if (userData.id) {
        localStorage.setItem('auth', userData.id);
        userHaveSubscription().then((response) => {
          setLoading(false);
          if (response.active && response.hasActiveSubscription) {
            localStorage.setItem('haveSubscription', true);
            goTo('/AlreadyHaveAccount');
          } else {
            goTo('/plans');
          }
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const handleRegister = async (e) => {
    if (errorMessage !== '') setErrorMessage('');
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await register(
        name,
        email,
        password,
        repeatPassword
      ).then((response) => {
        return response;
      });
      if (userData.id) {
        localStorage.setItem('auth', userData.id);
        await handleSendOtp(email)
          .then(() => {
            setLoading(false);
            goTo('/otp', { state: { email } });
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error.message);
          });
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const handleLogOut = async () => {
    setLoading(true);
    await logout()
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        if (error.message) {
          toast.error(t(error.message.split(' ')[0]), {
            onClose: () => {
              if (
                error.message.split(' ')[0] === 'Unauthorized,' ||
                error.message === 'notFound'
              ) {
                localStorage.removeItem('auth');
                localStorage.removeItem('haveSubscription');
                window.location.reload();
              }
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendOtp = async () => {
    setLoading(true);
    await sendOtp(email)
      .catch((error) => {
        if (error.message) {
          toast.error(t(error.message.split(' ')[0]), {
            onClose: () => {
              if (
                error.message.split(' ')[0] === 'Unauthorized,' ||
                error.message === 'notFound'
              ) {
                localStorage.removeItem('auth');
                localStorage.removeItem('haveSubscription');
                window.location.reload();
              }
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResendOtp = async (callbackFnc) => {
    setLoading(true);
    await resendOtp(email)
      .then(() => {
        callbackFnc();
      })
      .catch((error) => {
        if (error.message) {
          toast.error(t(error.message.split(' ')[0]), {
            onClose: () => {
              if (
                error.message.split(' ')[0] === 'Unauthorized,' ||
                error.message === 'notFound'
              ) {
                localStorage.removeItem('auth');
                localStorage.removeItem('haveSubscription');
                window.location.reload();
              }
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleVerifyOtp = async (otp) => {
    setLoading(true);
    await verifyOtp(email, otp)
      .then(() => {
        goTo('/plans');
      })
      .catch((error) => {
        if (error.message) {
          toast.error(t(error.message.split(' ')[0]), {
            onClose: () => {
              if (
                error.message.split(' ')[0] === 'Unauthorized,' ||
                error.message === 'notFound'
              ) {
                localStorage.removeItem('auth');
                localStorage.removeItem('haveSubscription');
                window.location.reload();
              }
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    name,
    email,
    password,
    errorMessage,
    loading,
    agree,
    repeatPassword,
    setName,
    setEmail,
    setPassword,
    setErrorMessage,
    setLoading,
    setAgree,
    setRepeatPassword,
    handleLogin,
    handleRegister,
    handleLogOut,
    handleSendOtp,
    handleResendOtp,
    handleVerifyOtp,
    goTo,
  };
};

export default AuthController;
