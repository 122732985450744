import api from '../api';

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/signin', {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};

export const register = async (name, email, password, repeatPassword) => {
  try {
    const response = await api.post('/auth/signup', {
      name,
      email,
      password,
      repeatPassword,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/users/logout');
    if (response.status === 200) {
      localStorage.removeItem('auth');
      return true;
    }
    return false;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};

export const sendOtp = async (email) => {
  try {
    const response = await api.post('/auth/confirmation-email', { email });
    if (response.status === 200) {
      localStorage.removeItem('auth');
      return true;
    }
    return false;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};

export const resendOtp = async (email) => {
  try {
    const response = await api.post('/auth/resend-confirmation-email', {
      email,
    });
    if (response.status === 200) {
      localStorage.removeItem('auth');
      return true;
    }
    return false;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};

export const verifyOtp = async (email, otp) => {
  try {
    const response = await api.post('/auth/verify-account', { email, otp });
    if (response.status === 200) {
      localStorage.removeItem('auth');
      return true;
    }
    return false;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data.message || 'Error en la autenticación'
      );
    } else {
      throw new Error('Error de conexión al servidor');
    }
  }
};
